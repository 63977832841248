import { lazy } from 'react';
import Loadable from './Loadable';


// Landing Page
const NotFoundPage = Loadable(lazy(() => import('../pages/404-error')));
const LandingPage = Loadable(lazy(() => import('../pages/home/LandingPage')));
const ContactUsPage = Loadable(lazy(()=> import('../pages/home/ContactUsPage')));
const ProductDetailPage = Loadable(lazy(()=> import('../pages/home/ProductsPage')));
const HealthCheck = Loadable(lazy(() => import('../pages/health')));

export const routes = () => {
  return [
    // Landing Page
    {  path: '/', element: <LandingPage /> },
    {  path: '/contact-us', element: <ContactUsPage /> },
    {  path: '/products/:id', element: <ProductDetailPage /> },
    {
      path: '/health',
      element: <HealthCheck />
    },


    // 404 Page
    {
      path: '*',
      element: <NotFoundPage />
    },
    

  ];
};